/* eslint-disable unicorn/numeric-separators-style */
import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu/Menu';
import MyHead from '../../components/MyHead';
import Footer from '../../components/Footer';
import MycarouselProducts from '../../components/Carousels/MycarouselProducts';
import MycarouselHeader from '../../components/Carousels/MycarouselHeader';
import MycarouselCategories from '../../components/Carousels/MycarouselCategories';
import MycarouselReviews from '../../components/Carousels/MycarouselReviews';
import { Reveal, RevealProps } from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import { fetchHome } from '../../fetchers/home';
import {
  getLangCountry,
  getRealLangCountry,
  SUPPORTED_LANG
} from '../../helpers/languages';
import { LANG_STATIC_PATHS } from '../../helpers/staticPaths';
import { fetchAlternateUrls } from '../../fetchers/commons';
import { GetStaticPropsContext, NextPage } from 'next';
import { SUPPORTED_COUNTRY } from '../../helpers/countries';
import { AlternateLangs } from '../../models/app';
import { IHome, IHomeProduct } from '../../models';
import Image from 'next/image';
import HomeBanner from '../../components/HomeBanner';
import { useInView } from 'react-intersection-observer';
import { fetchHomeProducts } from '../../fetchers/products';
import { getToken } from '../../helpers/auth';
import { useRouter } from 'next/router';
import Hero from '../../components/Home/Hero';
import { filter, flatten, isEmpty, map } from 'lodash';
import HomePopup from '../../components/Home/HomePopup';
import { useTranslation } from 'react-i18next';

interface IHomePageProps {
  alternates: AlternateLangs[];
  dataHome: IHome;
}

const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const revealProps = {
  triggerOnce: true,
  fraction: 0,
  delay: 50,
  duration: 450,
  keyframes: customAnimation
};

const Home: NextPage<IHomePageProps> = ({ alternates, dataHome }) => {
  const token = getToken();
  const router = useRouter();
  const { t } = useTranslation();
  const { lang, country } = getLangCountry(router.query);

  const [productsRef, productsInView] = useInView();
  const [products, setProducts] = useState<IHomeProduct[]>([]);
  const [productsLoading, setProductsLoading] = useState(false);

  let popupImage = '';

  switch (lang) {
    case 'it':
      popupImage = '/img/popup-valloire/Pop up_IT_Valloire.jpg';
      break;
    case 'fr':
      popupImage = '/img/popup-valloire/Pop up_FR_Valloire.jpg';
      break;
    case 'es':
      popupImage = '/img/popup-valloire/Pop up_ES_Valloire.jpg';
      break;
    case 'de':
      popupImage = '/img/popup-valloire/Pop up_DE_Valloire.jpg';
      break;
    default:
      popupImage = '/img/popup-valloire/Pop up_EN_Valloire.jpg';
  }

  const {
    header,
    'slider-categories': categories,
    'separator-image': separatorImage,
    // 'slider-products': products,
    'slider-quotations': reviews,
    banner
  } = dataHome || {};

  const slidesLang = flatten(
    map(
      filter(header, slide => slide.lang === lang),
      ({ sliders }) => sliders
    )
  );

  const slidesEN = flatten(
    map(
      filter(header, slide => slide.lang === 'en'),
      ({ sliders }) => sliders
    )
  );

  const slides = !isEmpty(slidesLang) ? slidesLang : slidesEN;

  const fetchCarouselProducts = async () => {
    setProductsLoading(true);

    const products = await fetchHomeProducts({ lang, country, token });

    setProducts(products);
    setProductsLoading(false);
  };

  useEffect(() => {
    if (products.length === 0 && productsInView) {
      fetchCarouselProducts();
    }
  }, [productsInView]);

  const custom_home_alternates = [
    { alternateLang: 'it', url: '/it_it/' },
    { alternateLang: 'de', url: '/de_de/' },
    { alternateLang: 'es', url: '/es_es/' },
    { alternateLang: 'fr', url: '/fr_fr/' },
    { alternateLang: 'en-gb', url: '/en_gb/' },
    { alternateLang: 'en', url: '/en_eu/' },
    { alternateLang: 'it-ch', url: '/it_ch/' },
    { alternateLang: 'de-ch', url: '/de_ch/' },
    { alternateLang: 'fr-ch', url: '/fr_ch/' },
    { alternateLang: 'x-default', url: '/' }
  ];
  return (
    <>
      <MyHead alternates={custom_home_alternates} />
      <div>
        <Menu />

        <Hero data={slides} />

        <div className="px-4 py-16 sm:py-120 sm:px-12 bg-grey-custom">
          <Reveal {...revealProps}>
            <MycarouselCategories categories={categories} />
          </Reveal>
        </div>

        <div
          style={{
            width: '100%',
            position: 'relative'
          }}
        >
          <Reveal {...revealProps}>
            <img src={separatorImage} alt="Jeep" />
          </Reveal>
        </div>

        <div ref={productsRef}>
          <Reveal {...revealProps}>
            <div className="px-4 py-16 sm:px-12 sm:py-120 bg-grey-custom">
              <MycarouselProducts
                loading={productsLoading}
                products={products}
              />
            </div>
          </Reveal>
        </div>

        {/* <MycarouselReviews reviews={reviews} /> */}
        <p className="px-4 pb-6 sm:px-12 bg-grey-custom">
          {t('HOME_ASTERISK')}
        </p>
        <HomeBanner banner={banner} />
        <Footer />
        {/* <HomePopup
          image={popupImage}
          popupCookie="jeepers-popup-valloire-2024"
          popupExpirationDays={1}
          link=""
        /> */}
      </div>
    </>
  );
};

export async function getStaticProps(context: GetStaticPropsContext) {
  const { params } = context;
  const { lang, country } = getLangCountry(params);

  const real = getRealLangCountry(params);
  if (
    !SUPPORTED_LANG.includes(real.lang) ||
    !SUPPORTED_COUNTRY.includes(real.country)
  ) {
    return { notFound: true };
  }

  const [dataHome = {}, alternates] = await Promise.all([
    fetchHome({ lang, country }),
    fetchAlternateUrls({
      url: '/',
      lang,
      country
    })
  ]);

  return {
    props: {
      alternates,
      dataHome
    },
    revalidate: 86400
  };
}

export async function getStaticPaths() {
  return {
    paths: LANG_STATIC_PATHS,
    fallback: 'blocking'
  };
}

export default Home;
